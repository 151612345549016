import { Button, CircularProgress, Grid } from "@mui/material"
import PaymentFiltersComponent from "components/ui/filters/PaymentFiltersComponent"
import PaymentTable from "components/ui/tables/PaymentTable"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useNavigationByRole from "hooks/useNavigationByRole"
import useTopPanel from "hooks/useTopPanel"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import { useSearchParams } from "react-router-dom"
import { paymentTypeSelectOptions } from "shared/selectOptionsList"
import * as XLSX from "xlsx"

const Payment = () => {
	const { setComponent } = useTopPanel()
	const { t, i18n } = useTranslation()
	const [refetch, setRefetch] = useState(false)
	const [open, setOpen] = useState(false)
	const [dataLoading, setDataLoading] = useState(false)
	const [paymentsDetails, setPaymentsDetails] = useState({})
	const axiosPrivate = useAxiosPrivate()
	const [searchParams, setSearchParams] = useSearchParams()
	const { navigateFn } = useNavigationByRole()

	useEffect(() => {
		setComponent(
			<div className="text-base-color text-xl font-medium">
				{t("payment.title")}
			</div>
		)
	}, [i18n.language])

	const handleGetData = () => {
		setDataLoading(true)
		let entries = Object.fromEntries(searchParams)
		let values = { from: entries?.from, till: entries?.till }
		axiosPrivate
			.post("/dictionary/paymentexport", JSON.stringify(values), {
				headers: { "Content-Type": "application/json" }
			})
			.then((res) => {
				if (res && res.data && res.data?.data) {
					let rows1 = res.data?.data[0].map((item) => ({
						"To'lov raqami": item?.id,
						"Shartnoma raqami": item?.contract?.name,
						Mijoz: `${item?.contract?.custom?.surname} ${item?.contract?.custom?.name} ${item?.contract?.custom?.middlename}`,
						Sana: moment(item?.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
						"Mas'ul": "",
						"To'lov turi": t(
							paymentTypeSelectOptions.find(
								(paymentType) => paymentType.code === item?.type_id
							)?.label
						),
						"To'lov maqsadi": "Shartnoma",
						Summa: item?.sum,
						"Ayirboshlash summasi": "",
						Tranzaksiya: "Kiruvchi"
					}))
					let rows = res.data?.data[0].map((item) => [
						item?.id,
						item?.contract?.name,
						`${item?.contract?.custom?.surname} ${item?.contract?.custom?.name} ${item?.contract?.custom?.middlename}`,
						moment(item?.date, "YYYY-MM-DD").format("DD.MM.YYYY"),
						// "",
						t(
							paymentTypeSelectOptions.find(
								(paymentType) => paymentType.code === item?.type_id
							)?.label
						),
						"Shartnoma",
						parseFloat(item?.sum),
						// "",
						"Kiruvchi"
					])
					rows.unshift([
						"To'lov raqami",
						"Shartnoma raqami",
						"Mijoz",
						"Sana",
						"To'lov turi",
						"To'lov maqsadi",
						"Summa",
						"Tranzaksiya"
					])

					let worksheet = XLSX.utils.aoa_to_sheet(rows)

					let maxWidthArr = Object.keys(rows1[0]).map((item) => {
						return {
							wch:
								rows1
									.map((r) => r[item])
									.reduce((acc, curr) => {
										return Math.max(acc, curr.toString().length)
									}, 15) + 2
						}
					})
					worksheet["!cols"] = maxWidthArr

					let fmt = "#,##0.00 [$UZS]"
					let range = { s: { r: 1, c: 6 }, e: { r: rows.length - 1, c: 6 } }
					for (let R = range.s.r; R <= range.e.r; ++R) {
						for (let C = range.s.c; C <= range.e.c; ++C) {
							let cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })]
							if (!cell || cell.t !== "n") continue
							cell.z = fmt
						}
					}

					let workbook = XLSX.utils.book_new()
					XLSX.utils.book_append_sheet(workbook, worksheet, t("payment.title"))
					XLSX.writeFile(
						workbook,
						`${t("payment.title")}_${moment(entries?.from, "YYYY-MM-DD").format(
							"DD-MM-YYYY"
						)}_${moment(entries?.till, "YYYY-MM-DD").format(
							"DD-MM-YYYY"
						)}.xlsx`,
						{
							type: "array",
							bookType: "xlsx"
						}
					)
				}
			})
			.catch((err) => {
				console.error(err)
			})
			.finally(() => setDataLoading(false))
	}

	return (
		<div className="component-list-wrapper">
			<div className="component-list-header mb-2">
				<div className="header-actions-container py-3 flex flex-row items-center">
					<div className="header-actions filter-box flex items-center my-shadow-2 rounded-lg px-4 w-full h-14">
						<PaymentFiltersComponent />
					</div>
					<div className="header-actions action-buttons-box py-3 px-4 my-shadow-2 rounded-lg flex items-center justify-center ml-4">
						<Button
							variant="action"
							color="info"
							onClick={() => {
								setRefetch(true)
							}}
							disable={`${refetch}`}
						>
							<i
								className={`bi bi-arrow-repeat${
									refetch ? " animate-spin" : ""
								}`}
							/>
						</Button>
						<Button
							variant="action"
							color="success"
							className="!mx-2"
							onClick={() => navigateFn("/admin/payment/add")}
						>
							<i className="bi bi-plus-circle" />
						</Button>
						<Button
							variant="action"
							color="primary"
							className="!mr-2"
							onClick={() => handleGetData()}
							disabled={dataLoading}
						>
							{dataLoading ? (
								<div className="flex items-center justify-center">
									<CircularProgress size={17.5} color="inherit" />
								</div>
							) : (
								<i className="bi bi-download" />
							)}
						</Button>
						<Button
							variant="action"
							color="default"
							onClick={() => setOpen(true)}
						>
							<i className="bi bi-gear" />
						</Button>
					</div>
				</div>
			</div>

			<div className="mb-5">
				<Grid
					container
					spacing={2}
					columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
				>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-base-color flex flex-col pl-2">
								<span className="text-sm text-gray-600">
									{t("payment.totalReceipts")}
								</span>
								{refetch ? (
									<CircularProgress
										size={20}
										color="inherit"
										className="mt-1"
									/>
								) : (
									<NumericFormat
										value={paymentsDetails?.pay}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium"
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-green-700 flex flex-col pl-2">
								<span className="text-sm text-gray-600">
									{t("common.typeList.paymentType.cash")}
								</span>
								{refetch ? (
									<CircularProgress
										size={20}
										color="success"
										className="mt-1"
									/>
								) : (
									<NumericFormat
										value={paymentsDetails?.naqd}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium"
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-base-color-light flex flex-col pl-2">
								<span className="text-sm text-gray-600">
									{t("common.typeList.paymentType.card")}
								</span>
								{refetch ? (
									<CircularProgress
										size={20}
										color="secondary"
										className="mt-1"
									/>
								) : (
									<NumericFormat
										value={paymentsDetails?.karta}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium"
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-blue-500 flex flex-col pl-2">
								<span className="text-sm text-gray-600">
									{t("common.typeList.paymentType.p2p")}
								</span>
								{refetch ? (
									<CircularProgress size={20} color="info" className="mt-1" />
								) : (
									<NumericFormat
										value={paymentsDetails?.p2p}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium"
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-orange-400 flex flex-col pl-2">
								<span className="text-sm text-gray-600">
									{t("common.typeList.paymentType.bank")}
								</span>
								{refetch ? (
									<CircularProgress
										size={20}
										color="warning"
										className="mt-1"
									/>
								) : (
									<NumericFormat
										value={paymentsDetails?.bank}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium"
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
					<Grid item={true} lg={2} md={4} sm={6} xs={12}>
						<div className="p-3 my-shadow-1 rounded-lg h-full flex items-center">
							<div className="border-l-[5px] border-red-500 flex flex-col pl-2">
								<span className="text-sm text-gray-600">
									{t("common.typeList.paymentType.act")}
								</span>
								{refetch ? (
									<CircularProgress size={20} color="error" className="mt-1" />
								) : (
									<NumericFormat
										value={paymentsDetails?.akt}
										allowNegative={false}
										displayType={"text"}
										thousandSeparator={" "}
										decimalScale={1}
										className="font-medium"
										suffix={" UZS"}
									/>
								)}
							</div>
						</div>
					</Grid>
				</Grid>
			</div>

			<div className="component-table-wrapper">
				<PaymentTable
					emitRefetch={{ refetch, setRefetch }}
					emitTableColumns={{ open, setOpen }}
					actionGetCheque={true}
					actionContractView={true}
					setPaymentsDetails={setPaymentsDetails}
				/>
			</div>
		</div>
	)
}

export default Payment
