import { ButtonBase, CircularProgress, Grid } from "@mui/material"
import { useFormik } from "formik"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import useCurrency from "hooks/useCurrency"
import useTopPanel from "hooks/useTopPanel"
import React, { Fragment, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useQueries } from "react-query"
import { useParams } from "react-router-dom"
import * as yup from "yup"
import FormSimpleSelectField from "components/ui/form/FormSimpleSelectField"
import FormCurrencyField from "components/ui/form/FormCurrencyField"
import {
	currencyTypeSelectOptions,
	repairTypeSelectOptions
} from "shared/selectOptionsList"
import CurrencySubContent from "components/ui/text-formats/CurrencySubContent"
import FormRadioGroupField from "components/ui/form/FormRadioGroupField"
import FormActionButtons from "components/ui/form/FormActionButtons"
import HOME_TYPE from "shared/homeTypeList"
import { NumericFormat } from "react-number-format"
import REPAIR_TYPE from "shared/repairTypeList"
import { formatMultiplies } from "utils/formatOddDecimal"
import BackButton from "components/ui/BackButton"
import SimpleToggleButtonGroup from "components/ui/simple-fields/toggle-button-group/SimpleToggleButtonGroup"
import FormSimpleMultipleSelectField from "components/ui/form/FormSimpleMultipleSelectField"
import useNotification from "hooks/useNotification"

const validationSchema = yup.object({
	stage: yup.number().required("shaxmatka.validation.stageNumberSelect"),
	square: yup.number().required("shaxmatka.validation.squareSelect"),
	isvalute: yup.string().optional(),
	repaired: yup.number().required("shaxmatka.validation.repaired"),
	norepaired: yup.number().required("shaxmatka.validation.norepaired"),
	isrepaired: yup.string().required("shaxmatka.validation.isrepaired")
})

const validationStagesSchema = yup.object({
	stages: yup.array().min(1).required("shaxmatka.validation.stageNumberSelect"),
	isvalute: yup.string().optional(),
	repaired: yup.number().required("shaxmatka.validation.repaired"),
	norepaired: yup.number().required("shaxmatka.validation.norepaired"),
	isrepaired: yup.string().required("shaxmatka.validation.isrepaired")
})

const BlockEdit = () => {
	const { id } = useParams()
	const { t, i18n } = useTranslation()
	const axiosPrivate = useAxiosPrivate()
	const [hasError, setHasError] = useState(false)
	const { currencyData } = useCurrency()
	const [selectedHomes, setSelectedHomes] = useState([])
	const [homeSquareSelectOptions, setHomeSquareSelectOptions] = useState([])
	const [homeStageSelectOptions, setHomeStageSelectOptions] = useState([])
	const [selectionType, setSelectionType] = useState("SQUARE_AND_STAGE")
	const [isSubmitting, setIsSubmitting] = useState(false)
	const sendNotification = useNotification()

	const { setComponent } = useTopPanel()

	const homesSelectionOptions = [
		{
			code: "SQUARE_AND_STAGE",
			label: "block.edit.selectionOptions.squareAndStage"
		},
		{
			code: "ONLY_STAGE",
			label: "block.edit.selectionOptions.stage"
		}
	]

	const formik = useFormik({
		initialValues: {
			stage: "",
			square: "",
			isvalute: "0",
			repaired: "",
			norepaired: "",
			isrepaired: null
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			let newValues = {
				isvalute: values.isvalute,
				repaired: values.repaired,
				norepaired: values.norepaired,
				isrepaired: values.isrepaired,
				_method: "put"
			}
			setIsSubmitting(true)
			try {
				let multipleResponse = await Promise.all(
					selectedHomes.map((homeId) =>
						axiosPrivate.post(
							`/admin/home/newprice/${homeId}`,
							JSON.stringify(newValues),
							{
								headers: { "Content-Type": "application/json" }
							}
						)
					)
				)
				handleMultipleResponse(multipleResponse)
			} catch (error) {
				sendNotification({
					msg: error?.response?.data?.message || error?.message,
					variant: "error"
				})
				setIsSubmitting(false)
			}
		}
	})

	const formikStages = useFormik({
		initialValues: {
			stages: [],
			isvalute: "0",
			repaired: "",
			norepaired: "",
			isrepaired: null
		},
		validationSchema: validationStagesSchema,
		onSubmit: async (values) => {
			let newValues = {
				isvalute: values.isvalute,
				repaired: values.repaired,
				norepaired: values.norepaired,
				isrepaired: values.isrepaired,
				_method: "put"
			}
			setIsSubmitting(true)
			try {
				let multipleResponse = await Promise.all(
					selectedHomes.map((homeId) =>
						axiosPrivate.post(
							`/admin/home/newprice/${homeId}`,
							JSON.stringify(newValues),
							{
								headers: { "Content-Type": "application/json" }
							}
						)
					)
				)
				handleMultipleResponse(multipleResponse)
			} catch (error) {
				sendNotification({
					msg: error?.response?.data?.message || error?.message,
					variant: "error"
				})
				setIsSubmitting(false)
			}
		}
	})

	const [homeQuery, blockQuery] = useQueries([
		{
			queryKey: "homesBlock" + id,
			queryFn: async function () {
				const response = await axiosPrivate.get(`admin/home/index/${id}`)
				return response.data.data
			},
			onSuccess: (data) => {
				setAllHomeSquare([...data])
			},
			enabled: !hasError && !!id,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		},
		{
			queryKey: "blockSingle" + id,
			queryFn: async function () {
				const response = await axiosPrivate.get(`/admin/block/edit/${id}`)
				return response.data.data
			},
			enabled: !hasError,
			onError: (error) => {
				setHasError(true)
			},
			retry: false
		}
	])

	const setAllHomeSquare = (homesData) => {
		setHomeSquareSelectOptions(
			Array.from(new Set(homesData.map((item) => item.square)))
				.sort()
				.map((item) => ({
					value: item,
					label: item.toString()
				}))
		)
	}

	const handleMultipleResponse = (multipleResponse) => {
		let result = multipleResponse.reduce((acc, curr) => {
			return acc && curr && curr.data && curr.data.status
		}, true)
		if (result) {
			sendNotification({
				msg: t("shaxmatka.alerts.success.homesUpdated"),
				variant: "success"
			})
			formik.resetForm()
			formikStages.resetForm()
			resetDisabling()
			setSelectedHomes([])
			homeQuery.refetch()
		}
		setIsSubmitting(false)
	}

	const resetDisabling = () => {
		Array.from(document.getElementsByClassName("home-item")).forEach((item) =>
			item.classList.remove("is-disabled")
		)
	}

	const handleCurrencyChange = (value) => {
		formik.setFieldValue("isvalute", value, true)
		if (currencyData && currencyData.sum) {
			if (value === "1") {
				formik.setFieldValue(
					"repaired",
					parseFloat(
						parseFloat(
							(formik.values.repaired || "0") / currencyData.sum
						).toFixed(1)
					),
					true
				)
				formik.setFieldValue(
					"norepaired",
					parseFloat(
						parseFloat(
							(formik.values.norepaired || "0") / currencyData.sum
						).toFixed(1)
					),
					true
				)
				formik.setFieldValue(
					"start",
					parseFloat(
						parseFloat((formik.values.start || "0") / currencyData.sum).toFixed(
							1
						)
					),
					true
				)
			} else if (value === "0") {
				formik.setFieldValue(
					"repaired",
					parseFloat((formik.values.repaired || "0") * currencyData.sum),
					true
				)
				formik.setFieldValue(
					"norepaired",
					parseFloat((formik.values.norepaired || "0") * currencyData.sum),
					true
				)
				formik.setFieldValue(
					"start",
					parseFloat((formik.values.start || "0") * currencyData.sum),
					true
				)
			}
		}
	}

	const getRoomsByStatus = (statusCode) => {
		return homeQuery?.data.length > 0
			? homeQuery?.data.filter((item) => item.status === statusCode).length
			: 0
	}

	const getFullFloorsNumberByBlock = () => {
		let a = Array.from(new Set(homeQuery?.data.map((item) => item.stage)))
		if (a.includes("-1")) {
			a.push("0")
		}
		a.sort((x, y) => x - y)
		return a.reverse()
	}

	const handleHomeSquareChange = (homeSquare) => {
		formik.setFieldValue("square", homeSquare, true)
		formik.setFieldValue("stage", "", true)
		setSelectedHomes([])
		setHomeStageSelectOptions(
			Array.from(
				new Set(
					homeQuery?.data
						.filter((item) => item.square === homeSquare)
						.map((item) => item.stage)
				)
			)
				.sort((a, b) => a - b)
				.map((item) => ({
					value: item,
					label: item.toString()
				}))
		)

		homeQuery?.data.forEach((item) => {
			item.square === homeSquare &&
			item.status !== HOME_TYPE.ORDERED.code &&
			item.status !== HOME_TYPE.TIME.code
				? document
						.querySelector(`.block-${id}-home#home-${item?.id}`)
						.classList.remove("is-disabled")
				: document
						.querySelector(`.block-${id}-home#home-${item?.id}`)
						.classList.add("is-disabled")
		})
	}

	const handleHomeStageChange = (homeStage) => {
		formik.setFieldValue("stage", homeStage, true)

		homeQuery?.data.forEach((item) => {
			if (
				(item.square === formik.values.square ||
					selectionType === "ONLY_STAGE") &&
				item.stage === homeStage &&
				item.status !== HOME_TYPE.ORDERED.code &&
				item.status !== HOME_TYPE.TIME.code
			) {
				setSelectedHomes((prev) => [...prev, item.id])
				document
					.querySelector(`.block-${id}-home#home-${item?.id}`)
					.classList.remove("is-disabled")
			} else {
				document
					.querySelector(`.block-${id}-home#home-${item?.id}`)
					.classList.add("is-disabled")
			}
		})
	}

	const handleHomeMultipleStageChange = (stages) => {
		formikStages.setFieldValue("stages", stages, true)
		let selectedHomesId = []
		if (stages && stages.length > 0) {
			homeQuery?.data.forEach((item) => {
				if (
					stages.includes(item.stage) &&
					item.status !== HOME_TYPE.ORDERED.code &&
					item.status !== HOME_TYPE.TIME.code
				) {
					selectedHomesId.push(item.id)
					document
						.querySelector(`.block-${id}-home#home-${item?.id}`)
						.classList.remove("is-disabled")
				} else {
					document
						.querySelector(`.block-${id}-home#home-${item?.id}`)
						.classList.add("is-disabled")
				}
			})
		} else {
			resetDisabling()
		}
		setSelectedHomes(selectedHomesId)
	}

	const handleSelectionType = (value) => {
		setSelectionType(value)
		resetDisabling()
		formik.resetForm()
		formikStages.resetForm()
		if (value === "SQUARE_AND_STAGE") {
			setHomeStageSelectOptions([])
		} else {
			setHomeStageSelectOptions(
				Array.from(new Set(homeQuery?.data.map((item) => item.stage)))
					.sort((a, b) => a - b)
					.map((item) => ({
						value: item,
						label: item.toString()
					}))
			)
		}
	}

	useEffect(() => {
		setComponent(
			<div className="flex flex-row items-center w-full">
				<BackButton />
				{blockQuery?.data && (
					<div className="text-xl">
						<span className="text-base-color">
							{blockQuery?.data?.objects?.name} | {blockQuery?.data?.name}
						</span>
					</div>
				)}
			</div>
		)
	}, [blockQuery?.data, i18n.language])

	return (
		<div className="flex">
			<div className="sheet-type-tabs is-mini">
				<div className="flex mb-1 items-center">
					<div className="flex items-center justify-between w-full ml-4 flex-wrap">
						<div className="flex mt-2">
							<div className="flex items-center">
								<div className="w-4 h-4 rounded-sm bg-gray-400 mr-1"></div>
								{t("shaxmatka.homeType.disabled")}
							</div>
							<div className="flex ml-4 items-center">
								<div className="w-4 h-4 rounded-sm bg-orange-400 mr-1"></div>
								{t("shaxmatka.homeType.ordered")}
							</div>
							<div className="flex ml-4 items-center">
								<div className="w-4 h-4 rounded-sm bg-red-500 mr-1"></div>
								{t("shaxmatka.homeType.sold")}
							</div>
						</div>
					</div>
				</div>

				<div className="sheet-wrapper">
					<div className="sheet-base-area">
						<div className="sheet-grid">
							{(blockQuery.isLoading || blockQuery.isFetching) &&
							(homeQuery.isLoading || homeQuery.isFetching) ? (
								<div className="circular-progress-box min-h-[500px] h-full w-full">
									<CircularProgress size={50} />
								</div>
							) : (
								homeQuery?.data &&
								homeQuery?.data.length > 0 && (
									<div
										className="sheet-column"
										key={`block-${id}-columns`}
										id={`block-${id}-columns`}
									>
										<div className="my-2 ml-10 leading-4">
											<span className="font-medium text-lg text-line-1">
												{blockQuery?.data?.name}
											</span>
											<span className="text-sm leading-4">
												({t("shaxmatka.homeType.free")}:{" "}
												{getRoomsByStatus(HOME_TYPE.ACTIVE.code)},{" "}
												{t("shaxmatka.homeType.ordered")}:{" "}
												{getRoomsByStatus(HOME_TYPE.TIME.code)},{" "}
												{t("shaxmatka.homeType.sold")}:{" "}
												{getRoomsByStatus(HOME_TYPE.ORDERED.code)})
											</span>
										</div>
										{getFullFloorsNumberByBlock().map((floorNumber, index) => (
											<div
												className={`sheet-row floor-${floorNumber}`}
												key={`block-${id}-row-${index}`}
												id={`block-${id}-row-${index}`}
											>
												<Grid
													container
													rowSpacing={1}
													columns={{
														xs: parseInt(
															new Set(homeQuery?.data.map((item) => item.stage))
																.size
														),
														sm: parseInt(
															new Set(homeQuery?.data.map((item) => item.stage))
																.size
														)
													}}
													key={`container-${floorNumber}`}
													className="sheet-home-row"
												>
													<Grid item={true} sm={1} xs={1}>
														<ButtonBase className="sheet-home-row-floor h-full w-8 !-ml-3 !mr-1 text-sm font-medium">
															{floorNumber}
														</ButtonBase>
													</Grid>
													{homeQuery?.data.length > 0
														? homeQuery?.data
																.filter((home) => home.stage === floorNumber)
																.map((item) =>
																	item?.contract?.id ? (
																		<Grid
																			item={true}
																			sm={1}
																			xs={1}
																			key={`home-${item.id}`}
																			className={`sheet-home-cell floor-${floorNumber}`}
																		>
																			<ButtonBase
																				className={`block-${id}-home home-item status-${
																					item.status
																				}${
																					item.disabled ? " is-disabled" : ""
																				}`}
																				id={`home-${item.id}`}
																			>
																				{!(
																					item.stage && parseInt(item.stage) < 0
																				) ? (
																					<Fragment>
																						<div className="home-item-data">
																							<span className="whitespace-nowrap mr-1">
																								№: {item.number}
																							</span>
																							{item?.contract?.sum &&
																								item?.contract?.discount &&
																								item?.contract?.square && (
																									<NumericFormat
																										value={
																											(parseFloat(
																												item.contract.sum
																											) -
																												parseFloat(
																													item.contract.discount
																												)) /
																											parseFloat(
																												item.contract.square
																											)
																										}
																										allowNegative={false}
																										displayType={"text"}
																										thousandSeparator={" "}
																										decimalScale={1}
																										className="w-full text-right"
																										suffix={
																											item?.isvalute === "1"
																												? " $"
																												: ""
																										}
																									/>
																								)}
																						</div>
																						<div className="home-item-data">
																							{item?.contract?.sum &&
																								item?.contract?.discount && (
																									<NumericFormat
																										value={
																											item.contract.sum -
																											item.contract.discount
																										}
																										allowNegative={false}
																										displayType={"text"}
																										thousandSeparator={" "}
																										decimalScale={1}
																										className="w-full text-center"
																										suffix={
																											item?.isvalute === "1"
																												? " $"
																												: " UZS"
																										}
																									/>
																								)}
																						</div>
																						<div className="home-item-data">
																							<span>{item.rooms}x</span>
																							<span>
																								<NumericFormat
																									value={item?.contract?.square}
																									allowNegative={false}
																									displayType={"text"}
																									decimalScale={2}
																									className="w-full text-right"
																								/>
																								<Trans i18nKey="common.global.meter">
																									m<sup>2</sup>
																								</Trans>
																							</span>
																						</div>
																					</Fragment>
																				) : (
																					<div className="h-[68px] flex items-center justify-center text-xl">
																						P
																					</div>
																				)}
																			</ButtonBase>
																		</Grid>
																	) : (
																		<Grid
																			item={true}
																			sm={1}
																			xs={1}
																			key={`home-${item.id}`}
																			className={`sheet-home-cell floor-${floorNumber}`}
																		>
																			<ButtonBase
																				className={`block-${id}-home home-item status-${
																					item.status
																				}${
																					item.disabled ? " is-disabled" : ""
																				}`}
																				id={`home-${item.id}`}
																			>
																				{!(
																					item.stage && parseInt(item.stage) < 0
																				) ? (
																					<Fragment>
																						<div className="home-item-data">
																							<span className="whitespace-nowrap">
																								№: {item.number}
																							</span>
																							{item.isrepaired ===
																							REPAIR_TYPE.REPAIRED.code ? (
																								<NumericFormat
																									value={item.repaired || ""}
																									allowNegative={false}
																									displayType={"text"}
																									thousandSeparator={" "}
																									decimalScale={1}
																									className="w-full text-right"
																									suffix={
																										item?.isvalute === "1"
																											? " $"
																											: ""
																									}
																								/>
																							) : (
																								<NumericFormat
																									value={item.norepaired || ""}
																									allowNegative={false}
																									displayType={"text"}
																									thousandSeparator={" "}
																									decimalScale={1}
																									className="w-full text-right"
																									suffix={
																										item?.isvalute === "1"
																											? " $"
																											: ""
																									}
																								/>
																							)}
																						</div>
																						<div className="home-item-data">
																							{item.isrepaired ===
																							REPAIR_TYPE.REPAIRED.code ? (
																								<NumericFormat
																									value={
																										item.repaired && item.square
																											? formatMultiplies([
																													item.repaired,
																													item.square
																											  ])
																											: 0
																									}
																									allowNegative={false}
																									displayType={"text"}
																									thousandSeparator={" "}
																									decimalScale={1}
																									className="w-full text-center"
																									suffix={
																										item?.isvalute === "1"
																											? " $"
																											: " UZS"
																									}
																								/>
																							) : (
																								<NumericFormat
																									value={
																										item.norepaired &&
																										item.square
																											? formatMultiplies([
																													item.norepaired,
																													item.square
																											  ])
																											: 0
																									}
																									allowNegative={false}
																									displayType={"text"}
																									thousandSeparator={" "}
																									decimalScale={1}
																									className="w-full text-center"
																									suffix={
																										item?.isvalute === "1"
																											? " $"
																											: " UZS"
																									}
																								/>
																							)}
																						</div>
																						<div className="home-item-data">
																							<span>{item.rooms}x</span>
																							<span>
																								<NumericFormat
																									value={item?.square}
																									allowNegative={false}
																									displayType={"text"}
																									decimalScale={2}
																									className="w-full text-right"
																								/>{" "}
																								<Trans i18nKey="common.global.meter">
																									m<sup>2</sup>
																								</Trans>
																							</span>
																						</div>
																					</Fragment>
																				) : (
																					<div className="h-[68px] flex items-center justify-center text-xl">
																						P
																					</div>
																				)}
																			</ButtonBase>
																		</Grid>
																	)
																)
														: [1].map((item) => (
																<Grid
																	item={true}
																	sm={1}
																	xs={1}
																	key={`home-empty-${item}`}
																	className={`sheet-home-cell sheet-home-empty-cell floor-${floorNumber}`}
																>
																	<ButtonBase className="home-item is-empty">
																		<div className="home-item-data"></div>
																	</ButtonBase>
																</Grid>
														  ))}
												</Grid>
											</div>
										))}
										<div className="mt-2 ml-10 leading-4">
											<span className="font-medium text-lg text-line-1">
												{blockQuery?.data?.name}
											</span>
											<span className="text-sm leading-4">
												({t("shaxmatka.homeType.free")}:{" "}
												{getRoomsByStatus(HOME_TYPE.ACTIVE.code)},{" "}
												{t("shaxmatka.homeType.ordered")}:{" "}
												{getRoomsByStatus(HOME_TYPE.TIME.code)},{" "}
												{t("shaxmatka.homeType.sold")}:{" "}
												{getRoomsByStatus(HOME_TYPE.ORDERED.code)})
											</span>
										</div>
									</div>
								)
							)}
						</div>
					</div>
				</div>
			</div>
			<div className="sheet-actions-area is-full">
				<div className="sheet-actions-body">
					<div className="sheet-form-action">
						<form
							onSubmit={
								selectionType === "SQUARE_AND_STAGE"
									? formik.handleSubmit
									: formikStages.handleSubmit
							}
						>
							<Grid
								container
								spacing={2}
								rowSpacing={1}
								columns={{ xs: 12, sm: 12 }}
							>
								<Grid item={true} sm={12} xs={12}>
									<SimpleToggleButtonGroup
										delay={0}
										value={selectionType}
										changeFn={handleSelectionType}
										options={homesSelectionOptions}
										size="small"
										fullWidth={true}
										buttonClassName="whitespace-nowrap"
									/>
								</Grid>

								{selectionType === "SQUARE_AND_STAGE" ? (
									<Fragment>
										<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
											<FormSimpleSelectField
												delay={0}
												fieldName="square"
												formik={formik}
												label={t("common.fields.homeAreAll")}
												options={homeSquareSelectOptions}
												itemValue="value"
												itemLabel="label"
												changeFn={handleHomeSquareChange}
											/>
										</Grid>

										<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
											<FormSimpleSelectField
												delay={0}
												fieldName="stage"
												formik={formik}
												label={t("common.fields.stageNumber")}
												options={homeStageSelectOptions}
												itemValue="value"
												itemLabel="label"
												changeFn={handleHomeStageChange}
											/>
										</Grid>

										<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
											<FormSimpleSelectField
												delay={0}
												fieldName="isvalute"
												formik={formik}
												label={t("common.fields.currency")}
												options={currencyTypeSelectOptions}
												itemValue="code"
												itemLabel="label"
												changeFn={handleCurrencyChange}
											/>
										</Grid>

										<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
											<FormCurrencyField
												delay={0}
												fieldName="repaired"
												formik={formik}
												label={
													<span>
														<Trans i18nKey="common.fields.repairedPrice">
															Ta'mirlangan narxi(1 m<sup>2</sup>)
														</Trans>
													</span>
												}
												decimalScale={1}
											/>
											{formik.values.isvalute === "1" &&
												!isNaN(formik.values.repaired) && (
													<CurrencySubContent
														value={formik.values.repaired || "0"}
													/>
												)}
										</Grid>

										<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
											<FormCurrencyField
												delay={0}
												fieldName="norepaired"
												formik={formik}
												label={
													<span>
														<Trans i18nKey="common.fields.noRepairedPrice">
															Ta'mirlanmagan narxi(1 m<sup>2</sup>)
														</Trans>
													</span>
												}
												decimalScale={1}
											/>
											{formik.values.isvalute === "1" &&
												!isNaN(formik.values.norepaired) && (
													<CurrencySubContent
														value={formik.values.norepaired || "0"}
													/>
												)}
										</Grid>

										<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
											<FormRadioGroupField
												delay={0}
												fieldName="isrepaired"
												formik={formik}
												label={t("common.fields.repairType")}
												options={repairTypeSelectOptions}
											/>
										</Grid>
									</Fragment>
								) : (
									<Fragment>
										<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
											<FormSimpleMultipleSelectField
												delay={0}
												fieldName="stages"
												formik={formikStages}
												label={t("common.fields.stageNumber")}
												options={homeStageSelectOptions}
												itemValue="value"
												itemLabel="label"
												changeFn={handleHomeMultipleStageChange}
											/>
										</Grid>

										<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
											<FormSimpleSelectField
												delay={0}
												fieldName="isvalute"
												formik={formikStages}
												label={t("common.fields.currency")}
												options={currencyTypeSelectOptions}
												itemValue="code"
												itemLabel="label"
												changeFn={handleCurrencyChange}
											/>
										</Grid>

										<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
											<FormCurrencyField
												delay={0}
												fieldName="repaired"
												formik={formikStages}
												label={
													<span>
														<Trans i18nKey="common.fields.repairedPrice">
															Ta'mirlangan narxi(1 m<sup>2</sup>)
														</Trans>
													</span>
												}
												decimalScale={1}
											/>
											{formik.values.isvalute === "1" &&
												!isNaN(formik.values.repaired) && (
													<CurrencySubContent
														value={formik.values.repaired || "0"}
													/>
												)}
										</Grid>

										<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
											<FormCurrencyField
												delay={0}
												fieldName="norepaired"
												formik={formikStages}
												label={
													<span>
														<Trans i18nKey="common.fields.noRepairedPrice">
															Ta'mirlanmagan narxi(1 m<sup>2</sup>)
														</Trans>
													</span>
												}
												decimalScale={1}
											/>
											{formik.values.isvalute === "1" &&
												!isNaN(formik.values.norepaired) && (
													<CurrencySubContent
														value={formik.values.norepaired || "0"}
													/>
												)}
										</Grid>

										<Grid item={true} sm={12} sx={{ marginBottom: "-0.5rem" }}>
											<FormRadioGroupField
												delay={0}
												fieldName="isrepaired"
												formik={formikStages}
												label={t("common.fields.repairType")}
												options={repairTypeSelectOptions}
											/>
										</Grid>
									</Fragment>
								)}

								<Grid item={true} sm={12} xs={12}>
									<FormActionButtons
										delay={0}
										isSubmitting={isSubmitting}
										onlySave
										className="mt-0"
									/>
								</Grid>
							</Grid>
						</form>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BlockEdit
